export class MockData {
  getMockdata() {
    return [
      {
        recordLocator: null,
        flightDate: "2024-05-06",
        lastUpdatedCache: "2024-05-06 02:41:18",
        utcFlightDepart: "2024-05-06 03:55:00",
        utcFlightArrival: "2024-05-06 05:10:00",
        flightArr: "KUL",
        flightCarrier: "AK",
        flightDep: "LGK",
        flightNumber: "AK 6309 ",
        checkinTable: "",
        boardGate: "7",
        baggageID: "",
        prev_carrierCode: "",
        prev_flightnumber: " ",
        prev_legcode: "  ",
        prev_departurestation: "KUL",
        prev_arrivalstation: "KUL",
        prev_std: null,
        prev_etd: null,
        prev_atd: null,
        prev_sta: null,
        prev_eta: null,
        prev_ata: null,
        rego: "9M-AQI",
        flight_map_flag: "True",
        flightStatus: "On Time",
        flightTDOWN_date: null,
        flightTDOWN_time: null,
        flightTDOWN_full: null,
        flightTDOWN_UTC: null,
        flightTKOFF_date: null,
        flightTKOFF_time: null,
        flightTKOFF_full: null,
        flightTKOFF_UTC: null,
        flight_arr_actual_date: null,
        flight_arr_actual_time: null,
        flight_arr_actual_full: null,
        flight_arr_actual_UTC: null,
        flight_dep_actual_date: null,
        flight_dep_actual_time: null,
        flight_dep_actual_full: null,
        flight_dep_actual_UTC: null,
        flight_arr_estimated_date: null,
        flight_arr_estimated_time: null,
        flight_arr_estimated_full: null,
        flight_arr_estimated_UTC: null,
        flight_dep_estimated_date: null,
        flight_dep_estimated_time: null,
        flight_dep_estimated_full: null,
        flight_dep_estimated_UTC: null,
        flight_arr_scheduled_date: "2024-05-06",
        flight_arr_scheduled_time: "1:10 PM",
        flight_arr_scheduled_full: "2024-05-06 13:10:00",
        flight_arr_scheduled_UTC: "2024-05-06 05:10:00",
        flight_dep_scheduled_date: "2024-05-06",
        flight_dep_scheduled_time: "11:55 AM",
        flight_dep_scheduled_full: "2024-05-06 11:55:00",
        flight_dep_scheduled_UTC: "2024-05-06 03:55:00",
      },
    ];
  }
}
