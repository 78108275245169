// export const environment = {
//   production: false,
//   uhfEnv: "dev",
//   gcsUrl: "https://sch.apiairasia.com",
//   searchUrl: "https://k.stg-apiairasia.com/",
//   searchUrlNew: "https://flightstatus-4zdy2ynl3a-as.a.run.app",
//   accessToken:
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJxUExvSEhyZHFrUDRXVWxGUTNPUWhjT1JqVHY3QUJFbyIsImlhdCI6MTUzMzcxMzk1MiwiZXhwIjoxODQ5MzMzMTUyLCJhdWQiOiJGbGlnaHRTdGF0dXMgU1RHIiwic3ViIjoiRmxpZ2h0U3RhdHVzIFNURyJ9.c6T_T-tQI0O4IL-UZ4dfp8qsqSlTQnt2Qo6NwvqHdxc",
//   localeBucketUrl: "https://sch.apiairasia.com/flightstatus/main/",
//   testData: "false",
//   notificationUrl: "https://sch.apiairasia.com/flightstatus/notificationstg/",
//   readMoreUrl: "https://sch.apiairasia.com/flightstatus/main/",
//   dateFormatUrl: "https://sch.apiairasia.com/dataformat/date/",
//   airportTerminalUrl: "https://sch.apiairasia.com/odterminal",
// };

export const environment = {
  production: false,
  uhfEnv: "dev",
  gcsUrl: "https://sch.apiairasia.com",
  searchUrl: "https://k.apiairasia.com/",
  // searchUrlNew: "https://flightstatus-4zdy2ynl3a-as.a.run.app",
  searchUrlNew: "https://flightstatus-4zdy2ynl3a-as.a.run.app/api/v2",
  accessToken:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMQ0FLaWhpbUxlTjZ3a1FYU2FhRTgyZTh0ZDUwM3NZUiIsImlhdCI6MTUzMzY5NzU5NSwiZXhwIjoxODQ5MzE2Nzk1LCJhdWQiOiJGbGlnaHQgU3RhdHVzIiwic3ViIjoiRmxpZ2h0IFN0YXR1cyJ9._M8lPMqvBYW20NcjeUyb0iQRrUYSjIWhGwJeDY4Apns",
  localeBucketUrl: "https://sch.apiairasia.com/flightstatus/main/",
  testData: "false",
  notificationUrl: "https://sch.apiairasia.com/flightstatus/notification/",
  readMoreUrl: "https://sch.apiairasia.com/flightstatus/main/",
  dateFormatUrl: "https://sch.apiairasia.com/dataformat/date/",
  airportTerminalUrl: "https://sch.apiairasia.com/odterminal",
  airportTerminalPageUrl:
    "https://aacsm--aauat.sandbox.my.site.com/s/airports-and-terminals",
  authUrl: "https://flightstatus-4zdy2ynl3a-as.a.run.app",
  imageStorage: "https://static.stgairasia.com/flight-status/images"
};
